<template>
    <div class="pc">
        <!-- banner -->
        <div class="nav-wrap" :style="isTop ? 'background: transparent' : 'background: #000'">
            <div class="main-content">
                <img class="nav-logo" src="//static.yssn.cn//ymy/uploads/20240522/eecafbf3577813746f522783df56c9c9.png" alt="">
                <div class="nav-list">
                    <template v-for="(item, index) in navList" :key={index}>
                        <a href="javascript:void(0)" :class="['list-item',{active: activeIndex === index}]"  @click="handleClick(item, index)">{{item.name}}</a>
                    </template>
                </div>
            </div>
            <div class="nav-right">合作联系：15626258625</div>
        </div>
        <div class="banner-wrap" id="index">
            <div class="banner-con p-a-cen-x ta-c">
                <img class="banner-title1" src="../../assets/pc/image/img_05.png" alt="">
                <span class="banner-title2">为逝去的亲友建一个永恒的生命纪念馆，您也一定有很多话想对他说</span>
            </div>
            <div class="banner-logo p-a-cen-x">
                <img :src="subcribe_code_url" alt="">
                <span>微信扫码关注“云上思念”公众号</span>
                <span>为逝去的亲友创建永久纪念馆</span>
            </div>
            <div class="banner-bottom">
                <div class="banner-bottom-con main-content">
                    <div class="flex-item">
                        <span class="item-num">500w</span>
                        <span class="item-name">纪念馆</span>
                    </div>
                    <div class="flex-item">
                        <span class="item-num">4320w</span>
                        <span class="item-name">祈福</span>
                    </div>
                    <div class="flex-item">
                        <span class="item-num">500w</span>
                        <span class="item-name">用户使用</span>
                    </div>
                    <div class="flex-item">
                        <span class="item-num">200w</span>
                        <span class="item-name">树洞</span>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <!-- 平台介绍 -->
        <div class="platform-introduction" id="">
            <div class="main-title ta-c">平台介绍</div>
            <div class="main-title-text ta-c">生命的终止不过一场死亡，死的意义不过在于重生或永眠，死亡不是失去生命，而是走出时间</div>
            <div class="flex-box clearfix">
                <div class="flex-left">
                    <img class="flex-left_img1" src="//static.yssn.cn//ymy/uploads/20221024/47371a5a8d03b65619b4f3e4a446ac83.jpg" alt="">
                    <img class="flex-left_img2" src="//static.yssn.cn//ymy/uploads/20221024/663560b6b83114e1a7a1609afa5cd02f.jpg" alt="">
                    <img class="flex-left_img3" src="//static.yssn.cn//ymy/uploads/20221024/e2cc8bdcc6ee4456ae04f1541a870304.jpg" alt="">
                </div>
                <div class="flex-right">
                    <div class="right-title" id="platformIntroduction">云上思念 | 新一代网上缅怀纪念平台</div>
                    <div class="right-text">云上思念是为全球用户免费提供网上纪念服务的平台，用户可以免费为故人创建网上纪念馆，书写故人生平、上传影像、发表追忆文章、在线祈福留言等，永久保存故人的数字遗产。随时随地进行祈福缅怀，同时也可以邀请亲朋好友加入亲友团，共同缅怀纪念，让生命以另一种形式延续！我们一起传递生命的故事，传递孝爱。</div>
                    <div class="right-scan">
                        <div class="scan-code">
                            <img :src="subcribe_code_url" alt="">
                        </div>
                        <div class="scan-text">
                            <span>扫码关注云上思念公众号</span>
                            <span>为故人创建纪念馆</span>
                            <a href="http://m.yssn.cn" target="_blank">http://m.yssn.cn</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="module-entry main-content">
                <div class="module-entry_item">
                    <img class="icon" src="../../assets/pc/image/icon_01.png" alt="">
                    <span class="item-name">免费建馆</span>
                    <span class="item-text">实现一键建馆，后期可以逐步完善资料</span>
                </div>
                <div class="module-entry_item">
                    <img class="icon" src="../../assets/pc/image/icon_02.png" alt="">
                    <span class="item-name">网上纪念</span>
                    <span class="item-text">免费创建生命纪念馆，永久保存数字遗产</span>
                </div>
                <div class="module-entry_item">
                    <img class="icon" src="../../assets/pc/image/icon_03.png" alt="">
                    <span class="item-name">思念有音</span>
                    <span class="item-text">为故人创建一颗守护星，让思念与天堂对话</span>
                </div>
                <div class="module-entry_item">
                    <img class="icon" src="../../assets/pc/image/icon_04.png" alt="">
                    <span class="item-name">心灵树洞</span>
                    <span class="item-text">思念情感的寄托和倾诉，存放心事，寄存秘密</span>
                </div>
            </div>
        </div>
        <!--  -->
        <!-- 平台服务 -->
        <div class="platform-sever" id="platformSever">
            <div class="main-title ta-c">平台服务</div>
            <div class="main-title-text ta-c">死亡不是在停止呼吸的那一刻，而是在最后一个记得你的人离开这个世界，当你彻底被世人遗忘，才是真正的“死亡”</div>
            <div class="sever-wrap">
                <div class="sever-item">
                    <img src="../../assets/pc/image/icon_05.png" alt="">
                    <div class="item-title">免费保存 传承后代</div>
                    <div class="item-text">云上思念网上纪念馆可以永久保存文字、照片、视频等，方便亲友随时查看、祈福，让子孙后代了解祖辈的故事。</div>
                </div>
                <div class="sever-item">
                    <img src="../../assets/pc/image/icon_06.png" alt="">
                    <div class="item-title">随时随地 一键祈福</div>
                    <div class="item-text">用户可以在云上思念网上纪念馆随时随地对故人进行深切缅怀，表达爱意，不受时间地点限制。</div>
                </div>
            </div>
            <div class="sever-wrap" style="margin-top: 100px">
                <div class="sever-item">
                    <img src="../../assets/pc/image/icon_08.png" alt="">
                    <div class="item-title">满足仪式感 如临其境</div>
                    <div class="item-text">人性化的界面设计，精致的纪念展示效果，满足用户的纪念仪式感需求，虽身在千里之外纪念如在身边。</div>
                </div>
                <div class="sever-item">
                    <img src="../../assets/pc/image/icon_07.png" alt="">
                    <div class="item-title">合作加盟 代客祭扫</div>
                    <div class="item-text">政策助推绿色环保祭扫，提供相关机构单位入驻和定制服务，携手云上思念共创绿色未来，实现共赢。</div>
                </div>
            </div>
        </div>
        <!--  -->
         <!-- 合作加盟 -->
        <div class="tomb-wrap" id="cooperate">
            <div class="main-title ta-c">合作加盟</div>
            <div class="main-title-text ta-c">成功助力多家墓园、机构实现业务增长。技术服务全面覆盖线上祭扫、殡仪、墓园等相关领域</div>
            <div class="tomb-wrap-flex">
                <div class="tomb-wrap-flex-item"
                v-for="(item, index) in cemeteryList" :key="item.id"
                @mouseenter="mouseOver(index)"
                @mouseleave="mouseOut()">
                    <img class="tomb-wrap-flex-item-img" :src="item.cover_img_url" alt="">
                    <div v-show="index == hoverIndex" class="tomb-wrap-flex-item-hover">
                        <img :src="item.code_url" alt="">
                        <span>{{item.name}}</span>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <!-- 纪念馆 -->
        <div class="memory-wrap" id="memory">
            <div class="main-title ta-c">纪念馆</div>
            <div class="main-title-text ta-c">你生来万丈光芒，而离开这世间，不过就是踏上了走向星辰的路</div>
            <div class="memory-swiper">
                <swiper 
                :modules="modules"
                :slides-per-view="3"
                :space-between="-150"
                :loop="true"
                navigation
                :pagination="{ clickable: true }"
                
                >
                    <swiper-slide v-for="(item, index) in tombList" :key="index"
                    @click="handleClickSwiper(item)">
                        <img :src="item.head_img_url" />
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <!--  -->
        <!-- 智慧陵园管理系统 -->
        <!-- <div class="wisdom-system" id="wisdomSystem">
            <div class="main-title ta-c">智慧陵园管理系统</div>
            <div class="flex-wrap">
                <div class="flex-item">
                    <img src="../../assets/pc/image/img_02.png" alt="">
                    <div class="text">功能个性化</div>
                    <div class="text">更灵活</div>
                </div>
                <div class="flex-item">
                    <img src="../../assets/pc/image/img_03.png" alt="">
                    <div class="text">随时登录操作</div>
                    <div class="text">更便捷</div>
                </div>
                <div class="flex-item">
                    <img src="../../assets/pc/image/img_01.png" alt="">
                    <div class="text">云存储服务</div>
                    <div class="text">更安全</div>
                </div>
                <div class="flex-item">
                    <img src="../../assets/pc/image/img_04.png" alt="">
                    <div class="text">提高管理效率</div>
                    <div class="text">更高效</div>
                </div>
            </div>
        </div> -->
        <!--  -->
       
        <!-- bottom -->
        <div class="bottom-wrap">
            <div class="bottom-wrap-content">
                <div class="item">
                    <div class="item-num">98w</div>
                    <div class="item-right">
                        <img class="item-right-icon" src="../../assets/pc/image/icon_09.png" alt="">
                        <span class="item-right-text">网上</span>
                        <span class="item-right-text">纪念馆</span>
                    </div>
                </div>
                <div class="item">
                    <div class="item-num">800w</div>
                    <div class="item-right">
                        <img class="item-right-icon" src="../../assets/pc/image/icon_11.png" alt="">
                        <span class="item-right-text">累积</span>
                        <span class="item-right-text">用户</span>
                    </div>
                </div>
                <div class="item">
                    <div class="item-num">2478w</div>
                    <div class="item-right">
                        <img class="item-right-icon" src="../../assets/pc/image/icon_12.png" alt="">
                        <span class="item-right-text">次</span>
                        <span class="item-right-text">祈福</span>
                    </div>
                </div>
                <div class="item">
                    <div class="item-num">872</div>
                    <div class="item-right">
                        <img class="item-right-icon" src="../../assets/pc/image/icon_10.png" alt="">
                        <!-- <span class="item-right-text">公墓</span> -->
                        <span class="item-right-text">服务商</span>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="footer-wrap" id="contactUs">
            <div class="footer-wrap-content">
                <div class="footer-left">
                    <div class="main-title">联系我们</div>
                    <div class="footer-text" style="margin-top: 26px">云上思念是由深圳市微友家园科技有限公司打造的一款传统文化与数字化信息结合的线上纪念平台。微友家园专注于互联网+传统信息服务领域，7年来为上千家企业提供咨询服务，经多轮融资，资本实力雄厚，已成为国内同行业的领导品牌。</div>
                    <div class="footer-text" style="margin-top: 30px">以弘扬中华传统祭祖文化，推动绿色殡葬改革为理念，开辟出线上和线下联动的创新纪念模式，将传统殡葬文化与科技事业完美结合，用务实笃行，把中国传统文化向着生态创新的方向不断推进。</div>
                    <div class="footer-text" style="margin-top: 30px">云上思念平台致力于为传统行业提供一站式网上纪念解决方案，为用户提供以线上纪念馆为基础的专业网络祈福服务。</div>
                    
                </div>
                <div class="footer-right">
                    <div class="flex-left">
                        <span class="text">联系方式</span>
                        <span class="text">
                            <span>联系电话: 15626258625</span>
                            <!-- <span class="indent-text1">13728992688</span> -->
                        </span>
                        <span class="text">客服电话: 15626258625</span>
                        <span class="text">
                            <span>地址: 深圳市宝安区西乡街道</span>
                            <span class="indent-text2">旭生大厦2215-2217</span>
                        </span>
                        <a class="ICP text" target="_blank" href="https://beian.miit.gov.cn/"><img src="https://cdn.zhuguanghui.com/img/beian2.png" width="14"> 粤ICP备17155564号</a>
                        <a target="_blank" class="ICP text" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602007191">
                            <img src="https://cdn.zhuguanghui.com/img/beian1.png" width="14"> 粤公网安备44030602007191号
		 		        </a>
                    </div>
                    <div class="flex-right">
                        <img :src="subcribe_code_url" alt="">
                        <span class="text">云上思念公众号</span>
                    </div>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="fix-kefu" @click="handleKefu">
            <img src="../../assets/pc/image/kefu.png" alt="">
        </div>
    </div>
</template>

<script>
import axios from '../../request/http.js';
import api from '../../request/api.js'
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import { Navigation, Pagination, A11y } from "swiper";

import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
// import "swiper/swiper-bundle.min.css"; //导入全部css

export default {
    name: 'pcIndex',
    components:{
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            navList: [
                {name: '首页', id: 'index'},
                {name: '平台介绍', id: 'platformIntroduction'},
                {name: '平台服务', id: 'platformSever'},
                {name: '合作加盟', id: 'cooperate'},
                {name: '纪念馆', id: 'memory'},
                // {name: '智慧陵园', id: 'wisdomSystem'},
                {name: '联系我们', id: 'contactUs'}
            ],
            activeIndex: 0,
            showPopup: false,
            modules: [Navigation, Pagination, A11y],
            tombList: [],
            cemeteryList: [],
            home_code_url: '',
            subcribe_code_url: '',
            hoverIndex: null,
            isTop: true
        }
    }, 
    created() {

    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
        this.getIndexInfo()
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods:{
        handleScroll() {
            if(window.pageYOffset === 0) {
                this.isTop = true
            } else {    
                this.isTop = false
            }
        },
        mouseOver(index) {
            this.hoverIndex = index
            // console.log('over',this.hoverIndex)
        },
        mouseOut() {
             this.hoverIndex = null
            //  console.log('out',this.hoverIndex)
        },
        getIndexInfo() {
            axios.axiosGet(api.getIndexInfo, {}).then(res =>{
                // console.log(res)
                this.tombList = res.data.tomb
                this.cemeteryList = res.data.cemetery
                this.home_code_url = res.data.info.home_code_url
                this.subcribe_code_url = res.data.info.subcribe_code_url
            })
            .catch(err =>{
                console.log(err)
            })
        },
        handleClickSwiper(item) {
            window.location.href = `http://m.yssn.cn/#/main/memory/${item.id}`
        },
        // cemeteryDetail(item) {
        //     window.location.href = `http://m.yunmuyuan.net/#/cemetery/detail/${item.id}`
        // },
        handleClick(item, index) {
            this.activeIndex = index
            const returnEle = document.querySelector("#" + item.id);
            if (returnEle) {
                returnEle.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
            }
        },
        handleKefu() {
            window.location.href = "https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97d07d03c0214598bf1bb0b0349f449d344f0ab92d0b5463290725b9cb22ca51c2a02a8ebb5189ece701bc127d21ea608c"
        }
    }
}
</script>
<style lang="less" scoped>
.pc{
    .main-content{
        width: 1200px;
        margin: 0 auto;
    }
    .main-title{
        font-size: 36px;
        font-weight: 500;
        color: #333;
        font-family: PingFangSC-Medium, PingFang SC;
    }
    .main-title-text{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-top: 22px;
    }
    .nav-wrap{
        width: 100%;
        height: 60px;
        position: fixed;
        left: 0;
        top: 0;
        // background-color: #000;
        z-index: 99;
        .main-content{
            height: 60px;
            position: relative;
        }
        .nav-logo{
            width: 167px;
            height: 36px;
            position: absolute;
            left: 0;
            top: 10px;
        }
        .nav-list{
            position: absolute;
            right: 0;
            top: 2px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .list-item{
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                margin-left: 42px;
                padding: 20px 0;
                box-sizing: border-box;
                position: relative;
                cursor: pointer;
                text-decoration: none;
               &:hover{
                   color: #fff;
               }
            }
            .active{
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                &:after{
                    content: '';
                    width: 100%;
                    height: 2px;
                    background-color: #fff;
                    position: absolute;
                    left: 0;
                    bottom: 4px;
                }
            }
        }
        .nav-right{
            position: absolute;
            right: 70px;
            top: 22px;
            font-weight: 500;
            font-size: 16px;
            color: #FFFFFF;
        }
    }

    .banner-wrap{
        width: 100%;
        height: 860px;
        background: url(../../assets/pc/image/banner.jpg)no-repeat;
        background-size: cover;
        position: relative;
        z-index: -1;
        margin-bottom: 120px;
        .banner-con{
            width: 1200px;
            top: 170px;
        }
        .banner-title1{
            width: 790px;
        }
        .banner-title2{
            width: 100%;
            display: block;
            margin-top: 40px;
            font-size: 30px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
        }
        .banner-logo{
            bottom: 205px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            img{
                width: 200px;
                display: block;
                margin-bottom: 20px;
            }
            span{
                font-size: 26px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
        .banner-bottom{
            width: 100%;
            height: 145px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: rgba(255, 255, 255, .2);
            &-con{
                width: 1200px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 30px;
                box-sizing: border-box;
                .flex-item{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: #fff;
                    .item-num{
                        position: relative;
                        font-size: 36px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        margin-bottom: 10px;
                         &:after{
                            content: '+';
                            font-size: 32px;
                            font-weight: 400;
                            position: absolute;
                            right: -23px;
                            top: -8px;
                        }
                    }
                     .item-name{
                        font-size: 30px;
                        font-weight: 400;
                    }
                }
            }
        }
    }

    .platform-introduction{
        width: 1200px;
        margin: 0 auto;
        // padding: 120px 0;
        box-sizing: border-box;
        margin-bottom: 120px;
        .flex-box{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: inherit;
            margin-top: 80px;
            .flex-left{
                position: relative;
                width: 60%;
                img{
                    position: absolute;
                    top: 0;
                    width: 260px;
                    z-index: 1;
                }
                &_img1{
                    right: 380px;
                }
                &_img2{
                    width: 280px !important;
                    top: -20px !important;
                    right: 180px;
                    z-index: 2 !important;
                }
                &_img3{
                    right: 0;
                }
            }
            .flex-right{
                width: 40%;
                padding: 60px 0 0 80px;
                box-sizing: border-box;
                .right-title{
                    font-size: 24px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #E4803C;
                }
                .right-text{
                    margin: 20px 0 24px 0;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 1.5;
                }
                .right-scan{
                    display: flex;
                    .scan-code{
                        width: 100px;
                        height: 100px;
                        margin-right: 21px;
                        img{
                            width: 100%;
                        }
                    }
                    .scan-text{
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                        color: #333;
                        font-size: 16px;
                    }
                }
            }
        }
        .module-entry{
            margin-top: 230px;
            display: flex;
            justify-content: space-between;
            &_item{
                width: 250px;
                height: 238px;
                border-radius: 20px;
                border: 1px solid #F2F2F2;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 45px 35px;
                box-sizing: border-box;
                text-align: center;
                cursor: pointer;
                &:hover{
                    background: #FAFAFA;
                    box-shadow: 0px 0px 30px 0px rgba(153, 153, 153, 0.24);
                    border-radius: 20px;
                    opacity: 0.99;
                    border: 1px solid #F2F2F2;
                }
                .icon{
                    width: 50px;
                }
                .item-name{
                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    margin: 15px 0 10px 0;
                }
                .item-text{
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 1.5;
                }
            }
        }
    }

    .platform-sever{
        width: 1200px;
        margin: 0 auto;
        box-sizing: border-box;
        .sever-wrap{
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-top: 77px;
            .sever-item{
                width: 480px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                img{
                  width: 50px;
                  height: 50px;  
                }
                .item-title{
                    font-size: 20px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    margin: 17px 0 12px 0;
                }
                .item-text{
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 1.5;
                }
            }
        }
    }

    .memory-wrap{
        width: 1200px;
        margin: 0 auto;
        margin-top: 120px;
        box-sizing: border-box;
            .memory-swiper{
                width: 100%;
                margin: 0 auto;
                margin-top: 10px;
                text-align: center;
                img{
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                    box-shadow: 0px 0px 18px 2px rgba(153, 153, 153, 0.44);
                    border: 10px solid #FFFFFF;
                }
                .swiper-slide-next{
                    img{
                        width: 180px !important;
                        height: 180px !important;
                        position: relative;
                        top: -10px;
                    }
                    
                }
            }        
    }

    .wisdom-system{
        width: 1200px;
        margin: 0 auto;
        margin-top: 120px;
        box-sizing: border-box;
        .flex-wrap{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .flex-item{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                img{
                  width: 280px;  
                }
                .text{
                    font-size: 20px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }
    }

    .tomb-wrap{
        width: 1200px;
        margin: 0 auto;
        margin-top: 120px;
        box-sizing: border-box;
        &-flex{
            width: 100%;
            margin-top: 80px;
            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            &-item{
                width: 350px;
                height: 280px;
                border-radius: 10px;
                overflow: hidden;
                position: relative;
                z-index: 5;
                cursor: pointer;
                display: inline-block;
                margin-right: 70px;
                margin-bottom: 50px;
                position: relative;
                &:nth-child(3n) {
                    margin-right: 0;
                }
                &-img{
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    position: absolute;
                    vertical-align: top;
                    -o-object-fit: cover;
                    object-fit: cover;
                    border-radius: 10px;
                }
                &-hover{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 10;
                    background: rgba(0, 0, 0, 0.6);
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    padding-top: 53px;
                    box-sizing: border-box;
                    cursor: pointer;
                    border-radius: 10px;
                    img{
                        width: 140px;
                        height: 140px;
                        display: block;
                        margin-bottom: 14px;
                    }
                    span{
                        font-size: 20px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        letter-spacing: 2px;
                    }
                }
            }
        }
    }

    .bottom-wrap{
        width: 100%;
        margin-top: 120px;
        height: 270px;
        background-color: #363226;
        &-content{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 75px;
            box-sizing: border-box;
            .item{
                display: flex;
                align-items: flex-end;
                // justify-content: flex-end;
                &-num{
                    font-size: 60px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    margin-right: 12px;
                    margin-bottom: -15px;
                }
                &-right{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    &-icon{
                        width: 50px;
                        margin-bottom: 9px;
                    }
                    &-text{
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    .footer-wrap{
        width: 100%;
        padding: 120px 0;
        background-color: #F5F9FC;
        box-sizing: border-box;
        &-content{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            .footer-left{
                width: 580px;
                margin-right: 220px;
                text-align: left;
                .footer-text{
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 1.8;
                }
            }
            .footer-right{
                padding-top: 66px;
                box-sizing: border-box;
                flex: 1;
                display: flex;
                justify-content: space-between;
                .text{
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin-top: 16px;
                }
                .indent-text1{
                    display: block;
                    text-indent: 4.5em;
                }
                .indent-text2{
                    display: block;
                    text-indent: 2.5em;
                }
                .flex-left{
                    display: flex;
                    flex-direction: column;
                }
                .flex-right{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img{
                        width: 150px;
                    }
                }
                .ICP{
                    
                }
            }
        }
    }

    .fix-kefu{
        position: fixed;
        right: 140px;
        bottom: 20%;
        width: 60px;
        height: 60px;
        background: #999999;
        border-radius: 50%;
        padding: 14px;
        box-sizing: border-box;
        cursor: pointer;
        z-index: 99;
        &:hover{
            background: #9b9b9b;
            box-shadow: 0px 0px 30px 0px rgba(153, 153, 153, 0.24);
            opacity: 0.99;
            transform: scale(1.05);
        }
        img{
            width: 32px;
            display: block;
        }
    }

}
    
</style>